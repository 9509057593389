import React from "react"
import Container from "../Layout/Container"
import { textStyles } from "../text.style"

const Testimonials = () => {
  const TestimonialsStyles = {
    sectionContainer: "mt-24",
    lineContainer:
      "xl:w-[79rem] mx-auto flex flex-row w-full overflow-hidden items-center",
    sectionTitle: "inline",
    line: "translate-y-[1.3rem] z-[-20] translate-x-[12rem] sm:translate-x-[15rem] block w-full xl:w-[400rem] h-px bg-primary",
    TestTitle:
      "w-48 sm:w-60 sm:tracking-[0.2rem] px-6 py-2 border border-primary rounded-full",
    article:
      "leading-normal tracking-widest h-60 font-light text-teal-400 text-sm italic ",
    readmore: "uppercase font-medium text-sm text-teal-400",
    arrow: "mt-2 ml-2",
    testimonialContainer:
      "hidescrollbar sal-delay snap-mandatory snap-x gap-14 my-8 flex py-10 flex-row overflow-x-scroll lg:justify-between lg:gap-1",
    testimonial: "snap-center flex flex-col flex-nowrap gap-y-4",
    quote:
      "text-base lg:text-xl italic font-medium text-white w-60 lg:w-64 xl:w-80 text-left	",
    author: "font-light italic text-sm lg:text-lg text-white/80 mt",
  }

  return (
    <div className={TestimonialsStyles.sectionContainer}>
      <Container>
        <div className={TestimonialsStyles.line}></div>
        <p className={TestimonialsStyles.TestTitle + textStyles}>
          Testimonials
        </p>
      </Container>

      <Container>
        <div
          className={TestimonialsStyles.testimonialContainer}
          data-sal="fade"
          data-sal-easing="easeInQuad"
        >
          <div className={TestimonialsStyles.testimonial}>
            <h4 className={TestimonialsStyles.quote}>
              “There was always a mysterious gap in the creative market. Ali has
              helped us to understand our goals and our clients by providing a
              brand strategy consultation."
            </h4>
            <h5 className={TestimonialsStyles.author}>
              Shahad
              <br />
              Co-Founder @ Desigate
            </h5>
          </div>
          <div className={TestimonialsStyles.testimonial}>
            <h4 className={TestimonialsStyles.quote}>
              “I know Mr. Ali Hammad as a hard working and very serious team
              player. Mr.Hammad is a great front end developer and designer, and
              certainly can get my full recommendation.”
            </h4>
            <h5 className={TestimonialsStyles.author}>
              Mohammed Hamel
              <br />
              Initiative Co-Founder @ AIIS
            </h5>
          </div>
          <div className={TestimonialsStyles.testimonial}>
            <h4 className={TestimonialsStyles.quote}>
              “It's been an amazing collabration with Ali Hammad, he was very
              cooperating, patient, and hard working about the challenges that
              happen during the process.”
            </h4>
            <h5 className={TestimonialsStyles.author}>
              Jamal Saeed
              <br />
              Team Leader @ Shakra
            </h5>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Testimonials
