import React, { useEffect, useState, useContext } from "react"
import { motion } from "framer-motion"
import { textContext } from "../../context"

const Cursor = () => {
  const [globalCoords, setGlobalCoords] = useState({
    x: 0,
    y: 0,
  })
  const { text, cursorStyle } = useContext(textContext)

  useEffect(() => {
    setGlobalCoords({
      x: window.innerWidth / 2,
      y: 35,
    })
    const handleWindowMouseMove = event => {
      setGlobalCoords({
        x: event.clientX,
        y: event.clientY,
      })
    }
    window.addEventListener("mousemove", handleWindowMouseMove)

    return () => {
      window.removeEventListener("mousemove", handleWindowMouseMove)
    }
  }, [])

  const variants = {
    default: {
      x: globalCoords.x - 16,
      y: globalCoords.y - 16,
      background: "none",
    },
    pearlot: {
      x: globalCoords.x - 75,
      y: globalCoords.y - 75,
      height: 150,
      width: 150,
      borderColor: "#B45C47",
      background: "#B45C47",
      color: "#FFFFFF",
    },
    stepwall: {
      x: globalCoords.x - 75,
      y: globalCoords.y - 75,

      height: 150,
      width: 150,
      borderColor: "#FFFFFF",
      background: "#FFFFFF",
      color: "#5465D1",
    },
    app: {
      x: globalCoords.x - 75,
      y: globalCoords.y - 75,

      height: 150,
      width: 150,
      borderColor: "#FBC75B",
      background: "#FBC75B",
      color: "#6B238E",
    },
  }

  return (
    <div className="hidden xl:block">
      <motion.div
        variants={variants}
        animate={cursorStyle}
        className="cursor relative"
        transition={{ ease: "linear", duration: 0.08 }}
      >
        <span className="absolute text-md font-semibold top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
          {text}
        </span>
      </motion.div>
    </div>
  )
}

export default Cursor
