import React, { useState } from "react"
import Container from "../Layout/Container"
import TextSM from "./TextSM"
import TextLG from "./TextLG"
import { textStyles } from "../text.style"

const About = () => {
  const [continueReading, setContinueReading] = useState(false)


  const AboutStyles = {
    sectionContainer: "mt-1",
    lineContainer:
      "sm:hidden flex flex-row w-full pr-6 overflow-hidden items-center",
    sectionTitle: "inline",
    line: "translate-x-[-33.5rem] sm:translate-x-[-10.5rem] lg:translate-x-[-10.5rem] xl:translate-x-[-332rem] block w-full translate-y-[1.5rem] z-[-20] xl:w-[400rem] h-px bg-primary",
    AboutTitle:
      "tracking-[0.1rem] w-40 sm:tracking-[0.2rem] text-center text-[16px] sm:text-xl font-medium px-6 py-2 translate-x-[185%] sm:translate-x-[29rem] md:translate-x-[33rem] lg:translate-x-[49rem] xl:translate-x-[68rem] border border-primary rounded-full uppercase  text-white",

    textWrapper: "my-12 lg:flex lg:flex-row sal-delay",
    textWrapperLG: "flex flex-row justify-between",
    linesm: "w-full h-px bg-primary",
    titlesm: "px-6 py-2 border border-primary rounded-full",
  }

  return (
    <div className={AboutStyles.sectionContainer}>
      <div className="hidden sm:block">
        <Container>
          <div className={AboutStyles.line}></div>
          <h3 className={AboutStyles.AboutTitle}>About</h3>
        </Container>
      </div>
      <div className={AboutStyles.lineContainer}>
        <div className={AboutStyles.linesm}></div>
        <h6 className={AboutStyles.titlesm + textStyles}>About</h6>
      </div>

      <div className={AboutStyles.textWrapper}>
        <Container>
          <div>
            <TextLG />

            <TextSM
              setContinueReading={setContinueReading}
              continueReading={continueReading}
            />
          </div>
        </Container>
      </div>
    </div>
  )
}

export default About
