import React from "react"

const TextLG = () => {
  const TextLGstyles = {
    article:
      "xl:w-80 lg:w-72 sm:text-xl xl:text-base mt-5 w-full overflow-y-hidden text-justify leading-normal tracking-widest font-light text-primary text-sm italic",

    textWrapperLG: "flex flex-row justify-between",
  }
  return (
    <div className="hidden lg:block">
      <div className={TextLGstyles.textWrapperLG}>
        <h4
          data-sal="slide-up"
          data-sal-easing="easeOutCirc"
          className={TextLGstyles.article}
        >
          I’m Ali Hammad, I provide high quality services in the digital
          creative field.
          <br />
          <br />I have started my career as a Brand designer, I’ve built
          different visual identities for different clients. This knowledge
          helped me a lot to become a better developer.
          <br />
          <br />
          Branding is a big field, it’s like a big tree with a lot of branches.
          I decided to specialize in Branding for digital products only.
          <br />
          <br />
        </h4>

        <h4
          data-sal="slide-up"
          data-sal-easing="easeOutCirc"
          className={TextLGstyles.article + " sal-delay-2"}
        >
          I’ve built my first website when I was in high school.
          <br />
          <br />
          I couldn’t forget the beauty and awesomeness of having your own
          website. So I decided to combine my knowledge in design, and my skills
          in development, to create digital products that have brand story and
          value.
          <br />
          <br />
          Today, we’re in the digital era. <br />
          Having your brand in the virtual world is as important as in the
          physical world, or usually, more important.
          <br />
          <br />
        </h4>
        <h4
          data-sal="slide-up"
          data-sal-easing="easeOutCirc"
          className={TextLGstyles.article + " sal-delay-3"}
        >
          As a UX Engineer, I believe that the good user experience you have,
          the better understanding your audience.
          <br />
          <br />
          Content creation is an essential part of any brand. It tells your
          story, and believes to the customers.
          <br />
          <br />
          My job is to combine user experience and brand story to create a
          high-value applications for my customers.
          <br />
          <br />I help you to choose the right technical solutions that suits
          your business budget.
          <br />
          <br />
        </h4>
      </div>
    </div>
  )
}

export default TextLG
