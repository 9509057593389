import AniLink from "gatsby-plugin-transition-link/AniLink"

import { StaticImage } from "gatsby-plugin-image"
import React, { useContext, useState } from "react"
import { textContext } from "../../context"

const ProjectLG = () => {
  const ProjectLGStyles = {
    flex: "hidden xl:flex flex-row h-96 2xl:h-[30rem] justify-between overflow-hidden ",
    left: "text-left py w-[50%] relative",
    right: "hover:cursor-none overflow-hidden w-[50%] width-full",
    subName: "sal-delay inline text-lg font-light text-20 text-white text-left",
    ProjectName:
      "sal-delay inline text-7xl font-semibold text-primary text-left",
    photoBox: "w-full h-full overflow-hidden",
    Vline: "hidden sm:block sm:h-[57rem] md:h-[30rem] w-[1.2px] bg-primary ",
    Photo:
      "w-full transition duration-1000 h-full object-cover overflow-hidden z-[-50]",
    Photo1:
      "w-full transition duration-1000 h-full object-cover overflow-hidden z-[-50]",
    Photo2:
      "w-full transition duration-1000 h-full object-cover overflow-hidden z-[-50]",
    Photo3:
      "w-full transition duration-1000 h-full object-cover overflow-hidden z-[-50]",

    textWrapper:
      "w-96 translate-y-[-50%] top-[45%] translate-x-[-50%] absolute left-[50%]",
  }

  const { setText, setCursorStyle } = useContext(textContext)

  const [photo1, setPhoto1] = useState(ProjectLGStyles.Photo)
  const [photo2, setPhoto2] = useState(ProjectLGStyles.Photo)
  const [photo3, setPhoto3] = useState(ProjectLGStyles.Photo)

  const handleHover = (style, i, text = "View") => {
    setText(text)
    setCursorStyle(style)
    if (i === 1) {
      setPhoto1(
        "w-full transition duration-1000 h-full object-cover overflow-hidden z-[-50] scale-110"
      )
    }
    if (i === 2) {
      setPhoto2(
        "w-full transition duration-1000 h-full object-cover overflow-hidden z-[-50] scale-110"
      )
    }
    if (i === 3) {
      setPhoto3(
        "w-full transition duration-1000 h-full object-cover overflow-hidden z-[-50] scale-110"
      )
    }
  }

  const handleLeave = () => {
    setText("")
    setCursorStyle("default")
    setPhoto1(ProjectLGStyles.Photo)
    setPhoto2(ProjectLGStyles.Photo)
    setPhoto3(ProjectLGStyles.Photo)
  }

  return (
    <>
      <div className={ProjectLGStyles.flex + " border-b border-primary "}>
        <div className={ProjectLGStyles.left}>
          <div className={ProjectLGStyles.textWrapper}>
            <h6
              className={ProjectLGStyles.subName}
              data-sal="fade"
              data-sal-easing="easeInQuad"
            >
              UI DESIGN + DEVELOPMENT
            </h6>
            <br />
            <h5
              className={ProjectLGStyles.ProjectName}
              data-sal="fade"
              data-sal-easing="easeInQuad"
            >
              PEARLOT
            </h5>
          </div>
        </div>
        <div className={ProjectLGStyles.Vline}></div>
        <AniLink
          paintDrip
          hex="#B45C47"
          to="/projects/pearlot"
          className={ProjectLGStyles.right}
          role="button"
          onMouseEnter={() => handleHover("pearlot", 1)}
          onMouseLeave={() => handleLeave()}
        >
          <StaticImage
            src="../../images/projects/webp/pearlot-screens.webp"
            className={photo1}
            alt="pearlot"
          />
        </AniLink>
      </div>
      <div className={ProjectLGStyles.flex + " border-b border-primary"}>
        <AniLink
          paintDrip
          hex="#5266E2"
          to="/projects/stepwall"
          className={ProjectLGStyles.right}
          role="button"
          onMouseEnter={() => handleHover("stepwall", 2)}
          onMouseLeave={() => handleLeave()}
        >
          <StaticImage
            src="../../images/projects/webp/stepwall.webp"
            className={photo2}
            alt="stepwall"
          />
        </AniLink>

        <div className={ProjectLGStyles.Vline}></div>

        <div className={ProjectLGStyles.left}>
          <div className={ProjectLGStyles.textWrapper}>
            <h6
              className={ProjectLGStyles.subName}
              data-sal="fade"
              data-sal-easing="easeInQuad"
            >
              UI DEVELOPMENT
            </h6>
            <br />
            <h5
              className={ProjectLGStyles.ProjectName}
              data-sal="fade"
              data-sal-easing="easeInQuad"
            >
              STEPWALL
            </h5>
          </div>
        </div>
      </div>
      <div className={ProjectLGStyles.flex}>
        <div className={ProjectLGStyles.left}>
          <div className={ProjectLGStyles.textWrapper}>
            <h6
              className={ProjectLGStyles.subName}
              data-sal="fade"
              data-sal-easing="easeInQuad"
            >
              MOBILE UI DEVELOPMENT
            </h6>
            <br />
            <h5
              className={ProjectLGStyles.ProjectName}
              data-sal="fade"
              data-sal-easing="easeInQuad"
            >
              PRONEF
            </h5>
          </div>
        </div>
        <div className={ProjectLGStyles.Vline}></div>
        <AniLink
          paintDrip
          hex="#FBC75B"
          to="/projects/pronef"
          className={ProjectLGStyles.right}
          role="button"
          onMouseEnter={() => handleHover("app", 3)}
          onMouseLeave={() => handleLeave()}
        >
          <StaticImage
            src="../../images/projects/webp/app-big.webp"
            className={photo3}
            alt="PRONEF"
          />
        </AniLink>
      </div>
    </>
  )
}

export default ProjectLG
