import React from "react"

const Container = ({ children }) => {
  const ContainerStyles =
    "w-full mx-auto px-5 sm:px-1 xl:w-[79rem] lg:w-[60rem] md:w-[44rem] sm:w-[40rem] container "

  return <div className={ContainerStyles}>{children}</div>
}

export default Container
