import React from "react"
import Project from "./Project"
import ProjectLG from "./ProjectLG"
import { textStyles } from "../text.style"

const Experience = () => {
  const styles = {
    Container:
      " bg-light w-full h-full pt-5 md:pt-8 xl:pt-0 border-b border-primary",
    sectionContainer: "sm:mt-[-6rem] md:mt-[-3rem]",
    lineContainer: "w-full ",
    line: "w-full h-[1px] bg-primary ",
    title:
      " sm:tracking-[0.2rem] w-[9.5rem] sm:w-[13rem] mx-auto translate-y-2/4 px-6 py-2 border border-primary rounded-full bg-[#202D36]",
  }

  return (
    <div className={styles.sectionContainer}>
      <div
        className={styles.lineContainer}
        style={{ zIndex: "500 !important" }}
      >
        <p className={styles.title + textStyles}>Projects</p>
        <div className={styles.line}></div>
      </div>
      <div className={styles.Container} style={{ zIndex: "1 !important" }}>
        <ProjectLG />
        <Project />
      </div>
    </div>
  )
}

export default Experience
