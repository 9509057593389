import React, { useEffect } from "react"
import Container from "../Layout/Container"
import ScrollOut from "scroll-out"
import { textStyles } from "../text.style"
import Technologies from "../../images/technologies.svg"

const Experience = () => {
  useEffect(() => {
    ScrollOut({
      onShown: function (el) {
        // use the web animation API
        el.animate(
          [
            { transform: "translateY(3.6rem) rotate(8deg)" },
            { transform: "translateY(0) rotate(0)" },
          ],
          500
        )
        el.once = true
      },
      onHidden: function (el) {
        el.style.transform = "translateY(0) rotate(0)"
      },
    })
  })

  const ExperienceStyles = {
    lineContainer: "w-full flex flex-row items-end ",
    sectionTitle: "inline",
    linesm: "w-[300%] translate-x-[-50%] h-[1px] bg-primary sm-hidden",
    line: "w-full  h-[1px] bg-primary sm-hidden",
    Vline:
      "hidden sm:block sm:h-[56rem] md:h-[57.5rem] mt-6 md:h-[60rem] w-[1px] bg-primary ",
    flex: "sm:flex sm:flex-row sm:justify-between",
    textWrapper: "pt-20 pb-14 sm:w-[46%] ",
    photoContainer: " mt-10 w-full lg:w-72 overflow-hidden",
    bigText:
      "opacity-0 uppercase text-5xl lg:text-6xl font-semibold text-primary leading-tight tracking-widest",
    smallText:
      "tracking-[0.2rem] lg:text-lg text-sm text-white font-medium leading-10",
    description:
      "sal-delay italic leading-normal tracking-widest lg:text-xl text-lg italigh font-light text-slate-400	mt-10",
    skillsContainer:
      "lg:w-96 sal-delay py-10 px-9 bg-black/10 rounded-3xl mt-14",
    skills:
      "text-white uppercase lg:text-xl text-tiny font-medium tracking-[0.2rem]",
    list: "lg:text-2xl text-primary list-disc ml-6 mt-5 text-base  font-semibold leading-loose tracking-widest",
    ExpTitle:
      "sm:tracking-[0.2rem] w-[12rem] sm:w-[13rem] mx-auto translate-y-2/4 px-6 py-2 border border-primary rounded-full",
    bigTextWrapper: "h-18 text-clip	overflow-hidden",
  }

  return (
    <div className="overflow-hidden">
      <div className={ExperienceStyles.lineContainer}>
        <div className={ExperienceStyles.line}></div>

        <h3 className={ExperienceStyles.ExpTitle + textStyles}>Experience</h3>
        <div className={ExperienceStyles.line}></div>
      </div>
      <Container>
        <div className={ExperienceStyles.flex}>
          <div className={ExperienceStyles.textWrapper}>
            <div>
              <h6 className={`${ExperienceStyles.smallText}  sm:mt-[0]`}>
                CREATIVE
              </h6>
              <div className={ExperienceStyles.bigTextWrapper}>
                <h2 className={ExperienceStyles.bigText} data-scroll>
                  BRAND/UI
                </h2>
              </div>
              <div className={ExperienceStyles.bigTextWrapper}>
                <h2 className={ExperienceStyles.bigText} data-scroll>
                  DESIGNER
                </h2>
              </div>

              <h5
                className={ExperienceStyles.description}
                data-sal="slide-up"
                data-sal-easing="easeOutCirc"
              >
                I design user experience and apply brand elements and values to
                user interfaces.
                <br />
                <br />
                I'm very glad to be certified by IBM as a Design Thinking
                Co-Creator.
              </h5>
              <div
                className={ExperienceStyles.skillsContainer}
                data-sal="fade"
                data-sal-easing="easeInQuad"
              >
                <p className={ExperienceStyles.skills}>skills</p>
                <ul className={ExperienceStyles.list}>
                  <li>UI DESIGN</li>
                  <li>UX RESEARCH</li>
                  <li>BRAND STRATEGY</li>
                </ul>
              </div>
            </div>
          </div>
          <div className={`${ExperienceStyles.linesm} sm:hidden`}></div>
          <div className={ExperienceStyles.Vline}></div>
          <div className={`${ExperienceStyles.textWrapper} sm:mb-10`}>
            <div>
              <div className={ExperienceStyles.bigTextWrapper}>
                <h5 className={ExperienceStyles.smallText}>CREATIVE</h5>

                <h2 className={ExperienceStyles.bigText} data-scroll>
                  FRONT-END
                </h2>
              </div>
              <div className={ExperienceStyles.bigTextWrapper}>
                <h2 className={ExperienceStyles.bigText} data-scroll>
                  DEVELOPER
                </h2>
              </div>
              <h5
                className={ExperienceStyles.description}
                data-sal="slide-up"
                data-sal-delay="10"
                data-sal-easing="easeOutCirc"
              >
                I develop modern web applications using React.js, Three.js,
                WebGL experience, smooth transition, creative web animation, and
                more.
                <br />
                <br />I combine the power of design, with web technologies to
                create unforgotable experience for the users.
              </h5>
              <div
                className={ExperienceStyles.skillsContainer}
                data-sal="fade"
                data-sal-easing="easeInQuad"
              >
                <p className={ExperienceStyles.skills}>Technologies</p>
                <div className={ExperienceStyles.photoContainer}>
                  <img
                    className="w-full object-cover"
                    src={Technologies}
                    alt="technologies"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Experience
