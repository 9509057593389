import React, { createContext, useEffect, useState } from "react"
import win from "global"

export const WindowContext = createContext()

export const WindowProvider = ({ children }) => {
  const [width, setWidth] = useState(win.innerWidth)

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(win.innerWidth), false)
  }, [])

  return (
    <WindowContext.Provider value={width}>{children}</WindowContext.Provider>
  )
}

export const textContext = createContext()

export const TextProvider = ({ children }) => {
  const [text, setText] = useState("")
  const [cursorStyle, setCursorStyle] = useState("default")

  return (
    <textContext.Provider
      value={{ text, setText, cursorStyle, setCursorStyle }}
    >
      {children}
    </textContext.Provider>
  )
}
