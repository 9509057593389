import React, { useState } from "react"
import Container from "../Layout/Container"
import Phone from "../../images/call.svg"
import Mail from "../../images/email.svg"

const Contact = () => {
  const [numberHover, setnumberHover] = useState(false)
  const [emailHover, setEmailHover] = useState(false)
  const [BehanceHover, setBehanceHover] = useState(false)
  const [LinkedInHover, setLinkedInHover] = useState(false)
  const [GithubHover, setGithubHover] = useState(false)
  const [InstagramHover, setInstagramHover] = useState(false)

  const ContactStyles = {
    links: " py-3 lg:py-6 border-y border-primary bg-light",
    list: "flex flex-row justify-between xl:ml-32 xl:mt-8",
    item: "text-primary font-medium text-sm lg:text-2xl xl:text-xl sm:text-base",
    itemHover: "w-0 mx-auto bg-primary h-px",
    line: "block w-full xl:w-[500rem] h-px bg-primary ",
    Vline: "hidden xl:block xl:h-[20rem] w-px bg-primary ",
    contacts:
      " xl:flex-col md:flex md:flex-row md:justify-between py-14 flex flex-col gap-6 font-medium xl:w-[100%]",
    number:
      " items-center xl:flex-row-reverse xl:text-right flex flex-row gap-6  text-xl sm:text-2xl lg:text-3xl xl:text-2xl tracking-widest	text-white ",
    email:
      "items-center xl:flex-row-reverse xl:text-right flex flex-row gap-6 text-xl sm:text-2xl lg:text-3xl xl:text-2xl tracking-widest	text-white",
    titleBox: "sal-contact xl:flex xl:flex-row",
    title:
      "xl:w-[32rem] font-semibold xl:text-left my-11 xl:mt-16 sm:text-center  animate-pulse  text-8xl sm:text-7xl md:text-8xl lg:text-9xl text-primary italic  leading-tight tracking-widest uppercase",
    PhotoCallBox: "min-w-6 min-h-6 w-6 h-6 lg:w-8 lg:h-8 xl:w-6 xl:h-6",
    PhotoEmailBox: "min-w-7 min-h-5 w-7 h-5 lg:w-10 lg:h-8 xl:w-7 xl:h-5",
    contactLG: "w-[60%]",
  }

  return (
    <div className="xl:border-t border-primary">
      <div className={`${ContactStyles.links} xl:hidden`}>
        <Container>
          <ul className={ContactStyles.list}>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                className={ContactStyles.item}
                href="https://www.behance.net/alihammad"
              >
                Behance
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                className={ContactStyles.item}
                href="https://www.linkedin.com/in/al1hammad/"
              >
                LinkedIn
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                className={ContactStyles.item}
                href="https://www.instagram.com/al1hammad/"
              >
                Instagram
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                className={ContactStyles.item}
                href="https://github.com/alihammad99"
              >
                Github
              </a>
            </li>
          </ul>
        </Container>
      </div>
      <Container>
        <div
          className={`${ContactStyles.contacts} xl:hidden`}
          data-sal="slide-up"
          data-sal-easing="easeInQuad"
        >
          <div className={ContactStyles.number}>
            <div className={ContactStyles.PhotoCallBox}>
              <img
                className="h-full w-full object-cover"
                src={Phone}
                alt="Phone"
              />
            </div>

            <h6>
              <a target="_blank" rel="noreferrer" href="tel:009647512077556">
                +964 751 207 7556
              </a>
            </h6>
          </div>
          <div className={ContactStyles.email}>
            <div className={ContactStyles.PhotoEmailBox}>
              <img
                className="h-full w-full object-fit"
                src={Mail}
                alt="Email"
              />
            </div>
            <h6>
              <a
                target="_blank"
                rel="noreferrer"
                href="mailto:hey@alihammad.net"
              >
                hey@alihammad.net
              </a>
            </h6>
          </div>
        </div>
      </Container>
      <div className={`${ContactStyles.line} xl:hidden`}></div>

      <Container>
        <div className={ContactStyles.titleBox}>
          <h2
            className={ContactStyles.title}
            data-sal="zoom-out"
            data-sal-easing="easeInQuad"
          >
            Get In Touch
          </h2>

          <div className={ContactStyles.Vline}></div>
          <div className={`${ContactStyles.contactLG} hidden xl:block`}>
            <div
              className={ContactStyles.contacts}
              data-sal="fade"
              data-sal-easing="easeInQuad"
            >
              <div className={ContactStyles.number}>
                <div className={ContactStyles.PhotoCallBox}>
                  <img
                    className={`${
                      numberHover && "animate-call"
                    } odd:h-full w-full object-cover`}
                    src={Phone}
                    alt="Phone"
                  />
                </div>

                <a
                  target="_blank"
                  rel="noreferrer"
                  href="tel:009647512077556"
                  onMouseOver={() => setnumberHover(true)}
                  onFocus={() => setnumberHover(true)}
                  onMouseLeave={() => setnumberHover(false)}
                  onBlur={() => setnumberHover(false)}
                >
                  +964 751 207 7556
                </a>
              </div>
              <div className={ContactStyles.email}>
                <div className={ContactStyles.PhotoEmailBox}>
                  <img
                    className={`${
                      emailHover && "animate-email"
                    } h-full w-full object-fit`}
                    src={Mail}
                    alt="Email"
                  />
                </div>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="mailto:hey@alihammad.net"
                  onMouseOver={() => setEmailHover(true)}
                  onFocus={() => setEmailHover(true)}
                  onMouseLeave={() => setEmailHover(false)}
                  onBlur={() => setEmailHover(false)}
                >
                  hey@alihammad.net
                </a>
              </div>
            </div>

            <div className={ContactStyles.line}></div>
            <ul className={ContactStyles.list}>
              <li>
                <a
                  onMouseOver={() => setBehanceHover(true)}
                  onFocus={() => setBehanceHover(true)}
                  onMouseLeave={() => setBehanceHover(false)}
                  onBlur={() => setBehanceHover(false)}
                  target="_blank"
                  rel="noreferrer"
                  className={ContactStyles.item}
                  href="https://www.behance.net/alihammad"
                >
                  Behance
                </a>
                <div
                  className={`${ContactStyles.itemHover} mt-1 ${
                    BehanceHover ? "animate-itemHover" : "animate-itemHoverOut"
                  }`}
                ></div>
              </li>
              <li>
                <a
                  onMouseOver={() => setLinkedInHover(true)}
                  onFocus={() => setLinkedInHover(true)}
                  onMouseLeave={() => setLinkedInHover(false)}
                  onBlur={() => setLinkedInHover(false)}
                  target="_blank"
                  rel="noreferrer"
                  className={ContactStyles.item}
                  href="https://www.linkedin.com/in/al1hammad/"
                >
                  LinkedIn
                </a>
                <div
                  className={`${ContactStyles.itemHover} mt-1 ${
                    LinkedInHover ? "animate-itemHover" : "animate-itemHoverOut"
                  }`}
                ></div>
              </li>
              <li>
                <a
                  onMouseOver={() => setInstagramHover(true)}
                  onFocus={() => setInstagramHover(true)}
                  onMouseLeave={() => setInstagramHover(false)}
                  onBlur={() => setInstagramHover(false)}
                  target="_blank"
                  rel="noreferrer"
                  className={ContactStyles.item}
                  href="https://www.instagram.com/al1hammad/"
                >
                  Instagram
                </a>
                <div
                  className={`${ContactStyles.itemHover}  mt-1 ${
                    InstagramHover
                      ? "animate-itemHover"
                      : "animate-itemHoverOut"
                  }`}
                ></div>
              </li>
              <li>
                <a
                  onMouseOver={() => setGithubHover(true)}
                  onFocus={() => setGithubHover(true)}
                  onMouseLeave={() => setGithubHover(false)}
                  onBlur={() => setGithubHover(false)}
                  target="_blank"
                  rel="noreferrer"
                  className={ContactStyles.item}
                  href="https://github.com/alihammad99"
                >
                  Github
                </a>
                <div
                  className={`${ContactStyles.itemHover} mt-1 ${
                    GithubHover ? "animate-itemHover" : "animate-itemHoverOut"
                  }`}
                ></div>
              </li>
            </ul>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Contact
