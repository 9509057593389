import React from "react"

const Loading = ({ loading }) => {
  const LoadingStyles = {
    container:
      " w-full z-[2000] h-screen fixed top-0 bg-teal-500 overflow-hidden ",
    hi: "text-4xl xl:text-5xl absolute bottom-8 xl:bottom-10 xl:left-10 left-7  font-bold text-white/70 animate-pulse",
  }
  return (
    <div
      style={{ zIndex: 500 }}
      className={`${LoadingStyles.container} ${loading && " animate-loading"}`}
    >
      <h5 className={LoadingStyles.hi}>Welcome</h5>
    </div>
  )
}

export default Loading
