import React from "react"
import Arrow from "../../images/arrow.svg"
const TextSM = ({ continueReading, setContinueReading }) => {
  const TextSMStyles = {
    article:
      " lg:w-80 sm:text-xl lg:text-2xl sal-delay mt-5 w-full overflow-y-hidden text-justify leading-normal tracking-widest font-light text-primary text-sm italic ",
    arrow: "sm:mt-3",
  }

  return (
    <div className="lg:hidden">
      <h4
        style={{
          overflow: continueReading ? "visible" : "hidden",
        }}
        className={TextSMStyles.article}
      >
        I’m Ali Hammad, I provide high quality services in the digital creative
        field.
        <br />
        <br />I have started my career as a Brand designer, I’ve built different
        visual identities for different clients. This knowledge helped me a lot
        to become a better developer.
        <br />
        <br />
        Branding is a big field, it’s like a big tree with a lot of branches. I
        decided to specialize in Branding for digital products only.
        <br />
        <br />
      </h4>
      {continueReading && (
        <>
          <h4 className={TextSMStyles.article}>
            I’ve built my first website when I was in high school.
            <br />
            <br />
            I couldn’t forget the beauty and awesomeness of having your own
            website. So I decided to combine my knowledge in design, and my
            skills in development, to create digital products that have brand
            story and value.
            <br />
            <br />
            Today, we’re in the digital era. <br />
            Having your brand in the virtual world is as important as in the
            physical world, or usually, more important.
            <br />
            <br />
          </h4>
          <h4 className={TextSMStyles.article}>
            As a UX Engineer, I believe that the good user experience you have,
            the better understanding your audience.
            <br />
            <br />
            Content creation is an essential part of any brand. It tells your
            story, and believes to the customers.
            <br />
            <br />
            My job is to combine user experience and brand story to create a
            high-value applications for my customers.
            <br />
            <br />I help you to choose the right technical solutions that suits
            your business budget.
            <br />
            <br />
          </h4>
        </>
      )}
      <div className="flex flex-row justify-start gap-2">
        <button
          onClick={() =>
            continueReading
              ? setContinueReading(false)
              : setContinueReading(true)
          }
          className="text-primary font-medium text-base sm:text-xl"
          style={{ display: continueReading && "visible" }}
        >
          {continueReading ? "COLLAPSE" : "CONTINUE READING"}
        </button>

        <img
          className={TextSMStyles.arrow}
          src={Arrow}
          style={{ transform: continueReading && "rotate(-180deg)" }}
          alt="read more"
        />
      </div>
    </div>
  )
}

export default TextSM
