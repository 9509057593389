import React from "react"

const Circles = () => {
  const CirclesStyles = {
    circle:
      "w-[15rem] h-[15rem] animate-circle lg:w-[40rem] lg:h-[40rem] absolute bg-teal-700 rounded-full blur-[12rem] lg:blur-[22rem] z-[-100]",
  }

  return (
    <div>
      <div
        className={`top-0 translate-x-[-40%] translate-y-[-20%]  ${CirclesStyles.circle}`}
      ></div>
      <div
        className={`top-[50rem] right-0 translate-x-[30%] translate-y-[-20%]  ${CirclesStyles.circle}`}
      ></div>
      <div
        className={`top-[100rem] translate-x-[-40%] translate-y-[-20%]  ${CirclesStyles.circle}`}
      ></div>
      <div
        className={`top-[150rem] right-0 translate-x-[30%] translate-y-[-20%]  ${CirclesStyles.circle}`}
      ></div>
      <div
        className={`top-[190rem] translate-x-[-40%] translate-y-[-12rem] animate-none ${CirclesStyles.circle}`}
      ></div>
      <div
        className={`top-[250rem] right-0 translate-x-[30%] translate-y-[-20%] sm:hidden  ${CirclesStyles.circle}`}
      ></div>
    </div>
  )
}

export default Circles
