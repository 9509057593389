import React, { useEffect, useState } from "react"
import { WindowProvider } from "../context"
import Header from "../components/Header"
import About from "../components/About"
import Experience from "../components/Experience"
import Projects from "../components/Projects"
import Testimonials from "../components/Testimonials"
import Contact from "../components/Contact"
import Loading from "../components/Loading"
import Circles from "../components/Layout/Circles"
import Cursor from "../components/Cursor"
import Portrait from "../images/portrait.webp"
import PearlotSM from "../images/projects/webp/pearlot-prev-sm.webp"
import Pearlot from "../images/projects/webp/pearlot-big.webp"
import Technologies from "../images/technologies.svg"
import { TextProvider } from "../context"
import { Helmet } from "react-helmet"

const Home = () => {
  const [loading, setLoading] = useState({})
  const [finishloading, setFinishLoading] = useState(false)

  useEffect(() => {
    setLoading({
      WindowProvider,
      Loading,
      Portrait,
      PearlotSM,
      Pearlot,
      Technologies,
    })
    setFinishLoading(true)
  }, [])

  return (
    <>
      <Helmet title="Ali Hammad | Creative Developer">
        <title>Ali Hammad | Creative Developer</title>
        <meta name="title" content="Ali Hammad | Creative Developer" />
        <meta
          name="description"
          content="Creative Ui/UX designer & Frontend React developer. I design and develop high-quality websites for brands"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://alihammad.net/" />
        <meta property="og:title" content="Ali Hammad | Creative Developer" />
        <meta
          property="og:description"
          content="Creative Ui/UX designer & Frontend React developer. I design and develop high-quality websites for brands"
        />
        <meta
          property="og:image"
          content="https://i.postimg.cc/s2TxrdPj/seo.png"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://alihammad.net/" />
        <meta
          property="twitter:title"
          content="Ali Hammad | Creative Developer"
        />
        <meta
          property="twitter:description"
          content="Creative Ui/UX designer & Frontend React developer. I design and develop high-quality websites for brands"
        />
        <meta
          property="twitter:image"
          content="https://i.postimg.cc/s2TxrdPj/seo.png"
        />
      </Helmet>
      <div className="overflow-x-hidden max-w-screen w-screen">
        <WindowProvider>
          <TextProvider>
            <Loading loading={finishloading} />
            <Circles />
            <Header loading={finishloading} />
            <About />
            <Experience />
            <Projects />
            <Testimonials />
            <Contact />
            <Cursor />
          </TextProvider>
        </WindowProvider>
      </div>
    </>
  )
}

export default Home
