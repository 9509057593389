import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Header = ({ loading }) => {
  const HeaderStyles = {
    container:
      " lg:w-[60rem] md:w-[44rem] sm:w-[40rem] xl:w-[79rem] translate-y-[-50%] absolute top-[50%] translate-x-[-50%] absolute left-[50%] lg:items-end flex flex-col lg:flex-row-reverse lg:justify-between lg:flex-row gap-6 sm:gap-8 mx-auto",
    textwrapper:
      "w-[19rem] sm:w-[31rem] md:w-[32rem] xl:w-[36rem] mx-auto lg:m-0 ",
    title:
      " translate-y-14 sm:translate-y-20 text-5xl sm:text-7xl md:text-8xl lg:text-6xl xl:text-7xl italic text-primary font-bold leading-tight tracking-[0.4rem] lg:tracking-[0.7rem]",
    name: "lg:ml-2 lg:text-left text-center translate-y-6 sm:translate-y-8 xl:translate-y-10 text-2xl sm:text-4xl md:text-4xl lg:text-3xl not-italic text-white leading-loose font-medium tracking-widest",
    PhotoContainer:
      "lg:m-0 overflow-hidden rounded-t-full	w-52 xl:w-[21rem] md:w-80 sm:w-72 mx-auto",
    Photo: "object-cover",
    nameContainer:
      "overflow-hidden h-8 sm:h-12 xl:h-18 md:h-15 mb-10 lg:mb-5 sm:mb-14",
    titleWrapper: "lg:text-left overflow-hidden",
  }
  return (
    <div className="h-screen sm:min-h-[60rem] lg:min-h-[1rem] relative">
      <div className={HeaderStyles.container}>
        <div className={HeaderStyles.PhotoContainer}>
          <StaticImage
            className={HeaderStyles.Photo}
            src="../../images/portrait.webp"
            alt="Ali Hammad"
          />
        </div>
        <div className={HeaderStyles.textwrapper}>
          <div className={HeaderStyles.nameContainer}>
            <h1
              className={`${HeaderStyles.name} ${
                loading && "animate-name delay"
              }`}
            >
              Ali Hammad
            </h1>
          </div>
          <div className={HeaderStyles.titleWrapper}>
            <h2
              className={`${HeaderStyles.title} ${
                loading && "animate-headerTitle delay"
              }`}
            >
              CREATIVE
            </h2>
          </div>
          <div className={HeaderStyles.titleWrapper}>
            <h2
              className={`${HeaderStyles.title} ${
                loading && "animate-headerTitle delay"
              }`}
            >
              DESIGNER &
            </h2>
          </div>
          <div className={HeaderStyles.titleWrapper}>
            <h2
              className={`${HeaderStyles.title} ${
                loading && "animate-headerTitle delay"
              }`}
            >
              DEVELOPER
            </h2>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
