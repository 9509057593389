import React, { useContext } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { StaticImage } from "gatsby-plugin-image"
import { Pagination } from "swiper"
import { WindowContext } from "../../context"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import "swiper/css"
import "swiper/css/pagination"
import "./swiper.css"

const Project = () => {
  const width = useContext(WindowContext)
  const ProjectStyles = {
    textWrapper: "text-center",
    PhotoContainer: "pb-3 overflow-hidde max-h-[24rem] sm:h-auto",
    projectName:
      "leading-tight tracking-wider mt-12 mb-4 text-5xl text-primary font-semibold uppercase",
    type: "mb-20 tracking-widest text-sm text-slate-400	 italic uppercase",
    slider: "text-center mt-16",
    swiper:
      "xl:hidden w-[75%] lg:w-[54rem] md:w-[40rem] sm:w-[34rem] sal-delay mt-[-5px] mb-4",
  }
  return (
    <Swiper
      spaceBetween={40}
      slidesPerView={width >= 1024 ? 3 : width >= 640 && width < 1024 ? 2 : 1}
      pagination={true}
      modules={[Pagination]}
      className={ProjectStyles.swiper}
      data-sal="flip-up"
      data-sal-easing="easeInQuad"
    >
      <SwiperSlide key="project1" className={ProjectStyles.slider}>
        <AniLink paintDrip hex="#B45C47" to="/projects/pearlot">
          <div className={ProjectStyles.PhotoContainer}>
            <StaticImage
              className="max-w-[17rem] max-h-[22rem] object-cover"
              src="../../images/projects/webp/pearlot-prev-sm.webp"
              alt="pearlot"
            />
          </div>
        </AniLink>
        <h3 className={ProjectStyles.projectName}>Pearlot</h3>
        <h6 className={ProjectStyles.type}>UI DESIGN & DEVELOPMENT</h6>
      </SwiperSlide>
      <SwiperSlide key="project2" className={ProjectStyles.slider}>
        <AniLink paintDrip hex="#5266E2" to="/projects/stepwall">
          <div className={ProjectStyles.PhotoContainer}>
            <StaticImage
              className="max-w-[17rem] max-h-[22rem] object-cover"
              src="../../images/projects/webp/stepwall-prev-sm.webp"
              alt="stepwall"
            />
          </div>
        </AniLink>
        <h3 className={ProjectStyles.projectName}>STEPWALL</h3>
        <h6 className={ProjectStyles.type}>UI DEVELOPMENT</h6>
      </SwiperSlide>
      <SwiperSlide key="project3" className={ProjectStyles.slider}>
        <AniLink paintDrip hex="#FFCDEA" to="/projects/pronef">
          <div className={ProjectStyles.PhotoContainer}>
            <StaticImage
              className="max-w-[17rem] max-h-[22rem] object-cover"
              src="../../images/projects/webp/app-prev-sm.webp"
              alt="PRONEF"
            />
          </div>
        </AniLink>
        <h3 className={ProjectStyles.projectName}>PRONEF</h3>
        <h6 className={ProjectStyles.type}>MOBILE UI DEVELOPMENT</h6>
      </SwiperSlide>
    </Swiper>
  )
}

export default Project
